@import "./config/minireset";
@import "./config/variables";
@import "./config/mixins";
@import "./config/responsive";
@import "./config/svg-images-refactored";
@import "./config/fonts";

html {
  font-size: $fnt_size_root;
  user-select: none;
  transform: translate3d(0, 0, 0);
  height: -webkit-fill-available;
  // overflow-y: auto; // NOTE: Why?
  overflow: hidden;
  @include mobile {
    font-size: $fnt_size_root-mobile;
  }
}

[data-whatintent="mouse"] *:focus {
  outline: none;
}

body {
  margin: 0;
  background: $base-color;
  color: $color-font;
  font-family: $fnt_family_default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100%;
  &.is-ios/*, &.is-ios .App*/{
    min-height: -webkit-fill-available;
  }

  #root{
    height: 100%;
  }

  #innerAppWrapper{
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  &.is-ios{
    #root{
      margin-bottom: calc(constant(safe-area-inset-bottom) * 2);
      margin-bottom: calc(env(safe-area-inset-bottom) * 2);
      height: inherit;
    }
    /*.App {
      min-height: 100vh;
      max-height: 100vh;
    }*/
  }
}

button {
  font-family: $fnt_family_default;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.loadbar {
  position: relative;
  display: block;
  width: 240px;
  margin-top: 1rem;
  progress {
    position: relative;
    display: block;
    width: 100%;
  }
  progress[value] {
    transition: width 0.3s ease-out;
  }
  progress[value]::-webkit-progress-value {
    background: lime;
  }
}

@mixin defButton() {
  position: relative;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: $base-color;
  border: none;
  > span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  background: $base-color;
  .media {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    svg {
      position: relative;
      display: inline-block;
      width: 300px;
      height: 150px;
      text {
        font-size: 36px !important;
      }
    }
  }

  .pseudoNavi {
    position: absolute;
    display: block;
    left: 40px;
    top: 0;
  }

  button.answer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    font-size: $fnt_size_l;
    margin: 0;
    padding: 12px;
    width: 100%;
    background-color: white;
    border: 1px solid rgba(black, 0.3);
    border-radius: 0.52em;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:not([disabled]):not(.xmpl) {
      &:hover {
        border: 1px solid rgba($color-orange, 1);
        box-shadow: 0 0 0 1px rgba($color-orange, 1);
      }
    }
    &.selected {
      border: 1px solid rgba(black, 0.3);
      background-color: $color-orange-30;
    }
    .ziggy {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 34px;
    }
    .media {
      max-width: 100%;
      max-height: 150px;
      svg {
        max-width: inherit;
        max-height: inherit;
      }
    }
    &[disabled] {
      border: 1px solid rgba(black, 0.1);
    }
  }

  a.pdfButton {
    @include defButton;
    cursor: pointer;
    width: 100%;
    max-width: 580px;
    padding: 15px;
    margin-top: 2rem;
    font-size: 18px;
    border: 1px solid $color-orange;
    border-radius: 0.75rem;
    color: inherit;
    text-decoration: none;
    opacity: 1;
    transition: opacity 0.2s ease-in;
    transition-delay: 0.7s;
    &.btnHidden {
      opacity: 0;
    }
  }
  .qrBox {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    max-width: 128px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
    transition-delay: 0.7s;
    &.qrHidden {
      opacity: 0;
    }
  }

  .group {
    position: relative;
    display: flex;
    align-items: center;
    a.pdfButton {
      margin-top: 0;
    }
  }

  button.answer,
  a.pdfButton {
    @include until($desktop) {
      min-height: 50px;
    }
  }

  .radio-group {
    display: flex;
    justify-content: space-between;
    padding-top: $padding-15;
    padding-bottom: $padding-15;
    position: relative;
    width: 100%;
    max-width: 310px;
    .btnRadio {
      position: relative;
      display: block;
      width: 50px;
      height: 50px;
      border: 1px solid $color-black-30;
      border-radius: 9px;
      background-color: $base-color;
      &.selected {
        background-color: $color-orange-30;
      }
      &:hover,
      &:focus {
        border: 2px solid $color-orange;
      }
      span {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.arrows {
      .btnRadio {
        span .symbol {
          display: block;
          width: 28px;
          height: auto;
          svg path {
            fill: $color-orange;
          }
        }
        &.val1 {
          span .symbol {
            width: 12px;
          }
        }
        &.val2 {
          span .symbol {
            width: 18px;
          }
        }
        &.val3 {
          span .symbol {
            width: 22px;
          }
        }
        &.val4 {
          span .symbol {
            width: 26px;
          }
        }
        &.val5 {
          span .symbol {
            width: 28px;
          }
        }
      }
    }
  }

  .feedback-item {
    .answers {
      position: relative;
      display: flex;
    }
  }

  .bubblelink {
    text-decoration: none;
    color: inherit;
  }

  #main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px; // NOTE: Magic number
    flex-grow: 1;
    flex-shrink: 0;

    @include tablet {
      padding-bottom: 100px; // NOTE: Magic number
    }

    .second-head {
      position: relative;
      display: flex;
      background: $color-blue;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 14px 14px 18px;
      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba($color-blue, 0);
        border-top-color: $color-blue;
        border-width: 20px;
        margin-left: -20px;
      }
      h2 {
        font-size: 1.25rem;
        text-align: center;
        margin: 0;
      }
      &.example {
        background: $color-orange;
        padding: 14px 140px 18px;
        &:after {
          border-color: rgba($color-orange, 0);
          border-top-color: $color-orange;
        }
        .xmpl {
          position: absolute;
          display: block;
          // left: $padding-section * 2;
          left: 30px; // NOTE: Magic but looks better
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.25rem;
          font-weight: $fnt_weight_medium;
          color: $color-font;
          &::before {
            position: relative;
            content: "\e926";
            font-family: "KC21";
            color: $color-font;
            margin-right: 0.4rem;
          }
        }
      }
      @include mobile {
        flex-wrap: wrap;
        &.example {
          padding: 14px 14px 18px;
          .xmpl {
            position: relative;
            display: block;
            top: 0;
            left: 0;
            flex: 1 0 100%;
          }
        }
      }
    }

    .answer-input {
      font-family: inherit;
      font-size: 18px;
      text-align: center;
      padding: 12px;
      border: 1px solid rgba(black, 0.3);
      border-radius: 10px;
      &:focus {
        border: 1px solid $color-orange;
      }
      &[disabled]:not(.example) {
        opacity: 0.5;
      }
      &[disabled].example {
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &::-ms-input-placeholder,
        &:-ms-input-placeholder {
          color: black !important;
          opacity: 1;
        }
        -webkit-text-fill-color: black;
        -webkit-opacity: 1;
        background-color: transparent;
      }
      @include until($desktop) {
        min-height: 50px;
      }
    }

    &.register {
      display: flex;
      flex-direction: column;
      p.intro {
        padding: 0 40px;
      }
      p.error {
        margin-top: $padding-30;
        color: $color-error;
      }
      .content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: $main-pad-dt $main-pad-mb $main-pad-dt;

        h2.title {
          font-size: $fnt_size_xl;
          margin-bottom: 0.75rem;
        }

        @include tablet {
          padding: $main-pad-dt $main-pad-tb $main-pad-dt;
        }
        @include desktop {
          padding: $main-pad-dt;
        }

        > .value-holder {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          min-height: 100%;
          width: 100%;
          max-width: 420px;
          @include mobile {
            flex-basis: 100%;
            max-width: 100%;
            padding: 0 $padding-section;
          }

          // NOTE: Password reset Link
          a.resetPwd {
            display: block;
            margin-top: 1rem;
            text-align: center;
            text-decoration: none;
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }

          > label,
          > .ll-formgroup > label {
            font-size: $fnt_size_xl;
            color: $color-blue;
            margin-bottom: $padding-section;
          }

          > .ll-formgroup > label {
            display: block;
            text-align: center;
            &.has-margin-top {
              margin-top: $padding-section * 1.5;
            }
          }

          input:not([type="checkbox"]),
          select {
            font-size: $fnt_size_m;
            font-weight: $fnt_weight_semibold;
            height: $padding-section * 2;
          }
        }
        p.fail {
          @include failFlag;
          font-weight: $fnt_weight_medium;
          text-align: center;
          padding: 3px 8px 4px;
          margin-top: 12px;
        }
      }
    }

    &.login,
    &.register {
      .second-head {
        display: none;
      }
    }

    .static-link {
      color: $color-blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@import "./components/GridContainer.scss";
@import "./components/FormElements.scss";
@import "./components/Assistant.scss";
@import "./components/Logo.scss";
@import "./components/MCP.scss";
@import "./components/Header.scss";
@import "./components/Footer.scss";
@import "./components/Templates.scss";
@import "./components/SplashScreen.scss";
@import "./components/ScreenInTheMiddle.scss";
@import "./components/Tutorial.scss";
@import "./components/Overlay.scss";
@import "./components/CookieBanner.scss";

.feedback {
  position: relative;
  display: block;
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  text-align: center;
  .icons {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
    .icon {
      font-size: 50px;
      margin: 0 10px;
    }
    &.right {
      .icon { color: $color-green; }
    }
    &.wrong, &.time {
      .icon { color: $color-orange; }
    }
  }
  .text {
    position: relative;
    display: block;
    font-size: 1.125rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  .tip {
    position: relative;
    display: inline-block;
    margin: 0 auto 1.5rem;
    text-align: left;
    ol {
      padding: 0 15px;
    }
  }
  button.answer {
    border-color: $color-orange;
  }
}
