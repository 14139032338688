.screen-in-the-middle {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;

  &.bubblesHidden .speech-bubble {
    opacity: 0;
    transform: scale(0);
  }

  @include mobile {
    .speech-bubble {
      max-width: 100%;
    }
  }

  @include tablet {
    justify-content: center;
    .speech-bubble {
      max-width: 90%;
    }
  }

  @include desktop {
    .speech-bubble {
      max-width: 800px;
    }
  }
}

.speech-bubble {
  position: relative;
  display: block;
  padding: $padding-section;
  border-radius: $padding-section/2;
  font-size: $fnt_size_xl;
  font-weight: $fnt_weight_medium;
  text-align: center;
  color: $base-color;
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0, 1.57, 0.4, 1.52);
  .content {
    padding: 0 !important;
    ol, ul {
      margin-left: $padding-section;
    }
  }
  &:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-width: $padding-section;
  }
  &.speech-bubble-top:after {
    bottom: 100%;
    left: 50%;
    margin-left: -$padding-section;
    margin-bottom: -1px;
  }
  &.speech-bubble-bottom:after {
    top: 100%;
    left: 50%;
    margin-left: -$padding-section;
    margin-top: -1px;
  }
  &.speech-bubble-left:after {
    right: 100%;
    top: 50%;
    margin-top: -$padding-section;
  }
  &.speech-bubble-right:after {
    left: 100%;
    top: 50%;
    margin-top: -$padding-section;
  }
  &.speech-bubble-main {
    background: $color-blue;
    transform-origin: center bottom;
    &.speech-bubble-top:after {
      border-bottom-color: $color-blue;
    }
    &.speech-bubble-bottom:after {
      border-top-color: $color-blue;
    }
    &.speech-bubble-right:after {
      border-left-color: $color-blue;
    }
    &.speech-bubble-left:after {
      border-right-color: $color-blue;
    }
  }
  &.speech-bubble-side {
    background: $color-orange;
    transform-origin: center top;
    transition-delay: 0.3s;
    &.speech-bubble-top:after {
      border-bottom-color: $color-orange;
    }
    &.speech-bubble-bottom:after {
      border-top-color: $color-orange;
    }
    &.speech-bubble-right:after {
      border-left-color: $color-orange;
    }
    &.speech-bubble-left:after {
      border-right-color: $color-orange;
    }
  }
}

.screen-in-the-middle.quest {
  .speech-bubble-side {
    .content {
      text-align: left;
      ol {
        margin-top: 0.5rem;
      }
    }
  }
}
