.mcp {
  body.virtual-keyboard-open &{
    display: none;
  }
  position: absolute;
  display: block;
  width: 113px;
  height: 113px;
  left: 50%;
  bottom: 15px;
  z-index: 3;
  transform: translateX(-50%);
  button {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0;
    padding: 0;
    background: white;
  }
  .time {
    position: absolute;
    display: block;
    z-index: auto;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $color-blue;
    font-size: 35px;
    transition: transform .2s ease-in-out;
  }
  svg {
    .background { fill: white; }
    .ring, .track, .progress { fill: none; }
    .ring, .track { stroke: $color-blue; }
    .ring { stroke-miterlimit: 10; }
    .track, .progress {
      stroke-linecap: round; // delete this or set to square (default) to get straight edges
      stroke-width: 4px;
    }
    .track { opacity: 1; }
    .progress {
      stroke: $color-orange;
      stroke-dasharray: 314.159; // circumference = 2 * π * radius (50) = 314,159265358979324
      // stroke-dashoffset: 157.0795; // set this to 0 to complete the circle (50% = 157,0795 etc.)
      transform-origin: 56.5px 56.5px;
      transform: rotate(-90deg); // By default the circle's start is at 3 o'clock
    }
    .status {
      .blue { path { fill: $color-blue; }}
      .stroked {path { stroke: #fff; }}
    }
  }

  &.timer {
    .time { display: block; }
    svg {
      .progress { display: none; }
      .track { opacity: 1; }
      .status #play,
      .status #double,
      .status #double_up { display: none; }
    }
    &.advanced {
      .time { transform: translate(-50%, -15px); }
      svg {
        .status #double_up { display: inline-block; }
      }
    }
  }

  &.tracker {
    .time { display: none; }
    svg {
      .track { opacity: 0.3; }
      .progress,
      .status #play { display: inline-block; }
      .status #double,
      .status #double_up { display: none; }
    }
  }

  &.proceed, &.interimScreen {
    .time { display: none; }
    svg {
      .progress { display: none; }
      .track { opacity: 1; }
      .status #play,
      .status #double_up { display: none; }
      .status #double { display: inline-block; }
    }
  }

  &.register {
    .time { display: none; }
    svg {
      .track { opacity: 0.3; }
      .progress,
      .status #double { display: inline-block; }
      .status #play,
      .status #double_up { display: none; }
    }
  }

  &.tutorial {
    .time { display: none; }
    svg {
      .track { opacity: 0.3; }
      .progress,
      .status #double { display: inline-block; }
      .status #play,
      .status #double_up { display: none; }
    }
  }

  &.disabled {
    svg { opacity: 0.7; }
    pointer-events: none;
  }

  @include mobile {
    width: 66px;
    height: 66px;
    .time {
      font-size: 20px;
    }
    &.timer.advanced {
      .time {
        transform: translate(-50%, -8px);
      }
    }
  }

}
