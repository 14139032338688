@mixin linedTasks {
  .task:not(:last-child) {
    border-bottom: 1px solid rgba($color-blue, 0.3);
    padding-bottom: 15px;
    margin-bottom: 24px;
  }
}

.template {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $main-pad-dt $main-pad-mb $main-pad-dt;

    @include tablet {
      padding: $main-pad-dt $main-pad-tb $main-pad-dt;
    }
    @include desktop {
      padding: $main-pad-dt;
    }

    .task {
      .question {
        position: relative;
        display: flex;
        align-items: center;
        img,
        .media {
          position: relative;
          display: block;
          width: 360px;
          height: auto;
        }
        > span,
        > math,
        > svg.mathml {
          color: $color-blue;
        }
        > span {
          position: relative;
          display: block;
          font-size: $fnt_size_xl;
          margin: 0;
        }
      }
      .question ~ .answers {
        // Give .answers top margin only if there is a question
        margin-top: $padding-section;
      }
    }

    &.rows {
      // Multiple items on one page
      .task {
        display: flex;
        flex-wrap: wrap;
        // flex: 1 0 auto;
        .question {
          padding-bottom: $padding-section;
          flex: 1 0 100%;
          max-width: 100%;
          margin-left: 0;
          justify-content: flex-start;
          align-items: center;
          &.num-only {
            justify-content: flex-start;
          }
          > span,
          > math {
            font-size: 1rem;
          }
          .item-num {
            color: $color-orange;
            margin-right: auto;
          }
          .item-num + * {
            margin-right: auto;
          }
          // > *:not(.item-num) {
          //   margin-right: auto;
          // }
          &.num-only {
            .item-num {
              margin-right: 0;
            }
          }
        }
        .question ~ .answers {
          margin-top: 0;
        }
        @include desktop {
          .question {
            padding-right: $main-pad-dt * 0.5;
            flex: 0 1 15%;
            &.fullwidth {
              flex: 0 1 100%;
              max-width: 100%;
            }
            &.num-only {
              flex: 0 1 $padding-section * 2;
              max-width: $padding-section * 2;
            }
          }
          .question.fullwidth + .grid.answers {
            width: 100%;
          }
          //.answers { margin-bottom: $main-pad-dt; }
        }
      }

      &.lined {
        @include linedTasks;
      }

      &.columns {
        .task {
          @include mobile {
            .question {
              margin-left: 0;
              flex: 0 1 100%;
              max-width: 100%;
            }
          }
          @include tablet-only {
            .question {
              flex: 0 1 50%;
              max-width: 50%;
            }
          }
        }
        @for $i from 1 through 2 {
          &.dt-#{$i} {
            @include desktop {
              column-count: $i;
              column-gap: $main-pad-dt;
            }
          }
          &.tbl-#{$i} {
            @include tablet {
              column-count: $i;
              column-gap: $main-pad-tb;
            }
          }
          &.mbl-#{$i} {
            @include mobile {
              column-count: $i;
              column-gap: $main-pad-mb;
            }
          }
        }
      }

      @include until($desktop) {
        @include linedTasks;
      }
    }

    &.center {
      text-align: center;
      .task .question {
        justify-content: center;
      }
    }
  }

  &.hub,
  &.welcome {
    @include mobile {
      .content {
        padding: 0 $main-pad-mb $main-pad-dt;
        .screen-in-the-middle {
          padding: 0;
        }
      }
    }
  }

  &.lueckentext {
    .content {
      .task {
        display: flex;
        .left,
        .right {
          flex: 1;
        }
        .left {
          text-align: left;
          .ib {
            display: inline;
            vertical-align: middle;

            &:not(:first-child) {
              margin-left: 0.2em;
            }
            &:not(:last-child) {
              margin-right: 0.2em;
            }

            &.gap {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background: $color-black-30;
              border-radius: 9px;
              width: 115px;
              height: 50px;
              box-shadow: none;
              &.selected {
                border: 1px solid $color-black-30;
                background: $base-color;
              }
              &.filled {
                background: $color-orange-30;
                border: 1px solid $color-black-30;
              }
            }

            &.text {
              line-height: 60px;
            }
          }
        }
        .right {
          .grid {
            margin-left: 0;
            margin-right: 0;
            justify-content: flex-start;
            .col {
              flex: none;
              width: 155px;
            }
          }
        }

        @include until($desktop) {
          .right .grid {
            justify-content: center;
          }
        }
      }
    }
  }

  &.zuordnen {
    .content {
      .task {
        display: flex;
        .left,
        .right {
          flex: 1;
        }
        .left {
          text-align: left;
          .segment {
            position: relative;
            display: flex;
            padding: 0 $main-pad-dt * 0.5 $main-pad-dt * 0.5;
            align-items: center;
            .z-text,
            .z-gap {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }
            .z-text {
              margin-left: -$main-pad-dt * 0.5;
              .num,
              .z-word {
                margin-right: auto;
              }
              .num {
                color: $color-orange;
              }
              .z-word {
                color: $color-blue;
                text-align: center;
                padding: 0 8px;
              }
            }
            .z-gap {
              background: $color-black-30;
              border-radius: 9px;
              height: 48px;
              box-shadow: none;
              &.selected {
                border: 1px solid $color-black-30;
                background: $base-color;
              }
              &.filled {
                background: $color-orange-30;
                border: 1px solid $color-black-30;
              }
              @include until($desktop) {
                min-height: 50px;
              }
            }
          }
        }
        .right {
          .grid {
            margin-left: 0;
            margin-right: 0;
            justify-content: center;
          }
          @include desktop {
            .grid {
              justify-content: flex-start;
              margin-right: -$main-pad-dt * 0.5;
            }
          }
        }
        &.xmpl {
          .left {
            .segment {
              .z-text {
                justify-content: flex-start;
              }
            }
          }
          .right {
            .grid {
              .col {
                padding: 0 $main-pad-dt * 0.25 $main-pad-dt * 0.5;
                button.answer {
                  min-height: 48px;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 440px) {
        // padding-bottom: 0;
        .task.xmpl {
          flex-wrap: wrap;
          min-height: 100%;
          flex: 1 0 auto;
          margin-bottom: -40px;
          .left {
            flex: 1 0 100%;
            max-width: 100%;
            .segment {
              padding: 0 0 $main-pad-dt * 0.5 $main-pad-dt * 0.5;
              justify-content: flex-start;
            }
          }
          .right {
            flex: 1;
            padding-left: 10px;
            background: $color-blue;
            margin: 1rem -15px 0;
            padding: 15px;
            min-height: 100%;
            .grid {
              // width: 100%;
              margin: 0 -#{$main-pad-dt * 0.25};
            }
          }
        }
      }
    }
  }

  &.preview-single-choice {
    .content.rows {
      .task {
        .question {
          > *:not(.item-num) {
            margin-right: 0;
          }
          flex: 1 0 100%;
          max-width: 100%;
          @include desktop {
            flex: 0 0 25%;
            max-width: 25%;
          }
        }
        &:not(:last-child) {
          padding-bottom: 1rem;
          border-bottom: 1px solid rgba($color-blue, 0.3);
        }
        &:not(:first-child) {
          padding-top: 1rem;
        }
        @include desktop {
          &:not(:last-child) {
            padding-bottom: 0;
            border-bottom: 0;
          }
          &:not(:first-child) {
            padding-top: 0;
          }
        }
      }
    }

    .preview-stage {
      position: relative;
      display: flex;
      justify-content: center;
      .slides {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        max-width: 580px;
        .slide {
          position: relative;
          display: flex;
          width: 95%;
          font-weight: $fnt_weight_medium;
          line-height: 1.5;
          border-radius: 9px;
          padding: 1rem;
          justify-content: center;
          box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
          margin-bottom: 1rem;
          .media {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            svg {
              position: relative;
            }
          }
        }
      }
    }
  }

  &.richtig-falsch {
    .content.rows.columns {
      @include desktop {
        display: block;
        .task {
          .question {
            flex: 1;
          }
          .answers {
            flex: 2;
          }
        }
      }
    }
  }

  &.fragebogen {
    .content {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      &:not(.fdbck) {
        padding: $main-pad-mb $main-pad-mb $main-pad-dt;
        @include tablet {
          padding: $main-pad-mb $main-pad-tb $main-pad-dt;
        }
        @include desktop {
          padding: $main-pad-mb $main-pad-dt $main-pad-dt;
        }
      }

      .fragebogen-item {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        border-bottom: 1px solid $color-blue-30;
        padding: $padding-15;
        &:last-child,
        &:nth-last-child(2) {
          border-bottom: none;
        }
        @include mobile {
          padding: 0;
          &:nth-last-child(2) {
            border-bottom: 1px solid $color-blue-30;
          }
        }
        @include tablet-only {
          &:nth-child(even) {
            padding-left: $padding-30;
            padding-right: 0;
          }
          &:nth-child(odd) {
            padding-left: 0;
            padding-right: $padding-30;
          }
        }
        @include from($tablet) {
          &:nth-child(even) {
            &:before {
              content: "";
              height: 100px;
              width: 1px;
              background: $color-blue-30;
              left: 0;
              top: 30px;
              position: absolute;
              @include tablet-only {
                height: 119px; // NOTE: span includes 2 lines of text
              }
            }
          }
        }
        h2 {
          span {
            display: block;
            padding-top: $padding-15;
            padding-bottom: $padding-15;
            @include mobile {
              padding-bottom: 0;
            }
            @include tablet-only {
              height: 68px; // NOTE: 2 lines of text + padding
            }
          }
        }
      }

      @for $i from 1 through 4 {
        &.dt-#{$i} {
          .fragebogen-item {
            @include desktop {
              flex: 0 0 percentage(1 / $i);
              max-width: percentage(1 / $i);
            }
          }
        }
        &.tbl-#{$i} {
          .fragebogen-item {
            @include tablet {
              flex: 0 0 percentage(1 / $i);
              max-width: percentage(1 / $i);
            }
          }
        }
        &.mbl-#{$i} {
          .fragebogen-item {
            @include mobile {
              flex: 0 0 percentage(1 / $i);
              max-width: percentage(1 / $i);
            }
          }
        }
      }
    }
  }

  &.feedback {
    .feedback-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;
      h2 {
        font-size: $fnt_size_l;
        margin-bottom: $fnt_size_xxl;
      }
      &:not(:first-child):not(.no-border) {
        border-top: 1px solid $color-blue;
      }
      &:first-child {
        padding: 0 0 30px;
      }
      &.no-border {
        padding: 0 0 30px;
      }
      &.disabled {
        h2 {
          opacity: 0.5;
        }
      }
      .container {
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 580px;
        .col {
          position: relative;
          display: flex;
          justify-content: center;
          padding: 0 5px 5px;
          @include tablet {
            padding: 0 $main-pad-tb * 0.5 $main-pad-tb * 0.5;
          }
          @include desktop {
            padding: 0 $main-pad-dt * 0.5 $main-pad-dt * 0.5;
          }
          > .radio-group {
            padding: 0;
          }
          > .answer-input {
            width: 100%;
          }
        }
        @for $i from 1 through 4 {
          &.dt-#{$i} {
            > .col {
              @include desktop {
                flex: 0 0 percentage(1 / $i);
                max-width: percentage(1 / $i);
              }
            }
          }
          &.tbl-#{$i} {
            > .col {
              @include tablet {
                flex: 0 0 percentage(1 / $i);
                max-width: percentage(1 / $i);
              }
            }
          }
          &.mbl-#{$i} {
            > .col {
              @include mobile {
                flex: 0 0 percentage(1 / $i);
                max-width: percentage(1 / $i);
              }
            }
          }
        }
        &.buts {
          margin-left: -$main-pad-mb * 0.5;
          margin-right: -$main-pad-mb * 0.5;
          @include tablet {
            margin-left: -$main-pad-tb * 0.5;
            margin-right: -$main-pad-tb * 0.5;
          }
          @include desktop {
            margin-left: -$main-pad-dt * 0.5;
            margin-right: -$main-pad-dt * 0.5;
          }
          .col.but {
            button.answer {
              padding: 12px 0;
            }
            // padding: 0 $padding-section;
          }
        }
      }
    }
  }

  &.not-found {
    justify-content: center;
    align-items: center;
  }

  .hint {
    position: relative;
    display: flex;
    text-align: left;
    padding: 0 $padding-section * 1.3;
    .headingHint {
      position: relative;
      font-weight: $fnt_weight_medium;
      font-size: 1.25rem;
      margin-bottom: 2px;
      &::before {
        content: "\e92e";
        font-family: "KC21";
        font-size: 0.95rem;
        position: absolute;
        display: inline-block;
        top: 0.4rem;
        left: -1.1rem;
        color: $color-orange;
      }
    }
    p {
      line-height: 1.4;
    }
  }
  .xmplDescription {
    position: relative;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    .container {
      position: relative;
      display: inline-block;
      margin: 0 auto;
      padding: $padding-section $padding-section * 2;
      color: $color-blue;
      font-weight: $fnt_weight_medium;
      border: 1px dotted rgba(black, 0.3);
      border-radius: 9px;
      > span::before {
        content: "\e928";
        color: rgba($color-orange, 1);
        font-family: "KC21";
        margin-right: 0.2rem;
        // position: absolute;
        // top: 3px;
        // left: 2px;
      }
    }
  }
  .foldable {
    position: relative;
    display: block;
    .foldable-closer {
      display: none;
      background: transparent;
      border: 0;
      color: white;
      font-size: 1.5625rem;
      padding: 0;
      width: 50px;
      height: 50px;
      top: 0;
      margin-left: auto;
      cursor: pointer;
    }
    @include until($desktop) {
      position: fixed;
      display: none;
      background: $color-blue;
      left: 0;
      width: 100vw;
      .foldable-closer {
        position: absolute;
        display: block;
        right: 0;
      }
      &.open {
        display: flex;
        flex-direction: column;
      }
      .answers {
        margin-top: 50px;
        height: 100%;
        overflow-y: scroll;
        justify-content: flex-start !important;
        .col {
          flex: 1 0 50% !important;
          max-width: 50%;
          width: auto !important;
        }
      }
    }
    @include mobile {
      top: auto;
      bottom: 63px;
      padding: 0 $main-pad-mb $main-pad-tb;
      max-height: 280px;
    }
    @include tablet {
      top: auto;
      bottom: 100px;
      padding: 0 $main-pad-tb $main-pad-tb;
      max-height: none;
    }
    @include desktop {
      top: 0;
      bottom: auto;
      padding: 0 $main-pad-tb $main-pad-tb;
      max-height: none;
    }
  }
}
